// import {
//   ArrowPathIcon,
//   CloudArrowUpIcon,
//   FingerPrintIcon,
//   LockClosedIcon,
// } from "@heroicons/react/24/outline";
import { useInView } from "framer-motion";
import { useRef } from "react";
import {
  PageBlocksChallenge,
  PageBlocksChallengeFeatures,
} from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";
import Features from "./Features";

// const features = [
//   {
//     name: "Push to deploy",
//     description:
//       "Morbi viverra dui mi arcu sed. Tellus semper adipiscing suspendisse semper morbi. Odio urna massa nunc massa.",
//     icon: CloudArrowUpIcon,
//   },
//   {
//     name: "SSL certificates",
//     description:
//       "Sit quis amet rutrum tellus ullamcorper ultricies libero dolor eget. Sem sodales gravida quam turpis enim lacus amet.",
//     icon: LockClosedIcon,
//   },
//   {
//     name: "Simple queues",
//     description:
//       "Quisque est vel vulputate cursus. Risus proin diam nunc commodo. Lobortis auctor congue commodo diam neque.",
//     icon: ArrowPathIcon,
//   },
//   {
//     name: "Advanced security",
//     description:
//       "Arcu egestas dolor vel iaculis in ipsum mauris. Tincidunt mattis aliquet hac quis. Id hac maecenas ac donec pharetra eget.",
//     icon: FingerPrintIcon,
//   },
// ];

export default function Challenge(props: Readonly<PageBlocksChallenge>) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  return (
    <div ref={ref} className="mx-auto max-w-7xl px-6 lg:px-8">
      <div
        className="mx-auto max-w-4xl lg:text-center"
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
      >
        <h2
          className="text-base leading-7 text-blue-600"
          data-tina-field={tinaField(props, "label")}
        >
          {props.label}
        </h2>
        <p
          className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
          data-tina-field={tinaField(props, "title")}
        >
          {props.title}
        </p>
        <p
          className="mt-6 text-lg leading-8 text-gray-600"
          data-tina-field={tinaField(props, "description")}
        >
          {props.description}
        </p>
      </div>
      {props.features && props.features?.length > 0 && (
        <Features
          features={props.features as unknown as PageBlocksChallengeFeatures[]}
        />
      )}
      {/* <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
        <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-16">
              <dt className="text-base font-semibold leading-7 text-gray-900">
                <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-blue-600">
                  <feature.icon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </div>
                {feature.name}
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div> */}
    </div>
  );
}
