import { BentoGrid, BentoGridItem, IconNames } from "../ui/bento-grid";
import { PageBlocksChallengeFeatures } from "tina/__generated__/types";

const getColumnSpan = (index: number) => {
  if (index === 2) return "md:col-span-2";
  if (index === 1) return "md:col-span-1";
  if (index === 3) return "md:col-span-3";
  return "md:col-span-1";
};

export default function Features({
  features,
}: Readonly<{
  features: PageBlocksChallengeFeatures[];
}>) {
  return (
    <BentoGrid className="mx-auto mt-10 max-w-7xl rounded-md bg-gray-100 bg-opacity-45 p-4 md:auto-rows-[20rem]">
      {features?.map((item, i) => (
        <BentoGridItem
          item={item}
          key={i}
          title={item.title}
          description={item.description}
          header={
            item.image ? (
              <>
                <div className="hidden h-full min-h-[6rem] w-full flex-1 rounded-xl md:flex">
                  <img
                    className="h-[190px] w-full pb-2"
                    src={item.image}
                    alt="Feature"
                  />
                </div>
                {item.imageMobile && (
                  <div className="flex h-full min-h-[6rem] w-full flex-1 rounded-xl md:hidden">
                    <img
                      className="h-[190px] w-full pb-2"
                      src={item.imageMobile}
                      alt="Feature"
                    />
                  </div>
                )}
              </>
            ) : (
              <Skeleton />
            )
          }
          className={getColumnSpan(item.columns ?? 1)}
          icon={(item.icon as IconNames) || null}
        />
      ))}
    </BentoGrid>
  );
}
const Skeleton = () => (
  <div className="dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex h-full min-h-[6rem] w-full flex-1 rounded-xl border border-transparent bg-neutral-100 [mask-image:radial-gradient(ellipse_at_center,white,transparent)] dark:border-white/[0.2] dark:bg-black"></div>
);
