/* eslint-disable import/namespace */
import { cn } from "~/lib/utils";
import * as FeaturedIcons from "@heroicons/react/24/outline";
import { PageBlocksChallengeFeatures } from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";

export type IconNames = keyof typeof FeaturedIcons;

export const BentoGrid = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "mx-auto grid max-w-7xl grid-cols-1 gap-4 md:auto-rows-[18rem] md:grid-cols-3",
        className,
      )}
    >
      {children}
    </div>
  );
};

export const BentoGridItem = ({
  item,
  className,
  title,
  description,
  header,
  icon,
}: {
  item: PageBlocksChallengeFeatures;
  className?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  header?: React.ReactNode;
  icon?: IconNames;
}) => {
  const IconComponent = icon ? FeaturedIcons[icon] : null;
  return (
    <div
      data-tina-field={tinaField(item, "title")}
      className={cn(
        "group/bento row-span-1 flex flex-col justify-between space-y-4 rounded-xl border border-transparent bg-white p-4 shadow-input transition duration-200 hover:shadow-xl dark:border-white/[0.2] dark:bg-black dark:shadow-none",
        className,
      )}
    >
      {header}
      <div className="transition duration-200 group-hover/bento:translate-x-2">
        {IconComponent && (
          <IconComponent className="h-6 w-6 text-neutral-600 dark:text-neutral-200" />
        )}{" "}
        {/* Render the icon component */}
        <div className="mb-2 mt-2 font-sans font-bold text-neutral-600 dark:text-neutral-200">
          {title}
        </div>
        <div className="font-sans text-xs font-normal text-neutral-600 dark:text-neutral-300">
          {description}
        </div>
      </div>
    </div>
  );
};
