import { Link } from "@remix-run/react";
import { motion } from "framer-motion";
import { tinaField } from "tinacms/dist/react";
import { ArrowRight } from "lucide-react";
import type { PageBlocksHero } from "tina/__generated__/types";

export default function Hero(props: Readonly<PageBlocksHero>) {
  return (
    <div className="relative pt-14">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#fde179] to-[#73cfa8] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
        />
      </div>
      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h1
              className="text-4xl tracking-tight text-gray-900 sm:text-6xl"
              data-tina-field={tinaField(props, "message")}
            >
              {props.message}
            </h1>
            <p
              className="mt-6 text-lg leading-8 text-gray-600"
              data-tina-field={tinaField(props, "sub_message")}
            >
              {props.sub_message}
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-8">
              {props.cta?.map((cta) => {
                if (!cta) return null;
                if (cta.primary) {
                  return (
                    <Link
                      data-tina-field={tinaField(cta, "label")}
                      to={cta.link}
                      key={cta.label}
                      className="group flex items-center justify-center space-x-6 rounded-full bg-zinc-800 py-1.5 pl-4 pr-2 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    >
                      {cta.label}
                      <div className="ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-blue-400 group-hover:bg-zinc-800">
                        <ArrowRight className="h-7 w-7 group-hover:translate-x-1 group-hover:scale-110 group-hover:transition-all" />
                      </div>
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      data-tina-field={tinaField(cta, "label")}
                      to={cta.link}
                      key={cta.label}
                      className="group text-sm font-semibold leading-6 text-gray-900 transition delay-75 ease-in-out hover:scale-125 hover:text-blue-400"
                    >
                      {cta.label} <span aria-hidden="true">→</span>
                    </Link>
                  );
                }
              })}
            </div>
          </div>
          <div className="mt-16 flow-root sm:mt-24">
            <motion.div
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
                <img
                  alt="App screenshot"
                  src="/images/remarc-app.webp"
                  width={1200}
                  height={872}
                  className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
                />
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#fde179] to-[#73cfa8] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
        />
      </div>
    </div>
  );
}
