import { Form } from "@remix-run/react";
import { PageBlocksWaitlist } from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";

export default function WaitList({
  submitted,
  language,
  isLoading,
  message,
  subMessage,
  buttonLabel,
  successMessage,
  helperText,
  buttonWait,
  errors,
  ...rest
}: Readonly<{
  submitted: boolean;
  language: string;
  isLoading: boolean;
  message: string;
  subMessage: string;
  buttonLabel: string;
  successMessage: string;
  helperText: string;
  buttonWait: string;
  errors:
    | {
        message: string;
      }[]
    | null
    | undefined;
  rest: PageBlocksWaitlist;
}>) {
  const wait = rest.rest;
  return (
    <div
      id="waitlist"
      className="relative -z-10 my-10 mt-32 w-full bg-indigo-500 p-6 lg:p-8"
    >
      <div className="mx-auto max-w-7xl text-center">
        <h2
          className="text-3xl tracking-tight text-white sm:text-4xl"
          data-tina-field={tinaField(wait, "message")}
        >
          {message}
        </h2>

        <p
          className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white/80"
          data-tina-field={tinaField(wait, "sub_message")}
        >
          {subMessage}
        </p>
        <div className="mx-auto w-full text-center">
          <div className="mx-auto px-4 py-5 sm:p-6">
            {submitted || wait.show_success ? (
              <div
                className="mx-auto w-full rounded-lg bg-white p-6 text-center text-lg text-indigo-600 shadow-md md:w-1/3"
                data-tina-field={tinaField(wait, "success_message")}
              >
                {successMessage}
              </div>
            ) : (
              <>
                {errors && (
                  <div className="mx-auto w-full rounded-lg bg-white p-6 text-center text-lg text-red-600 shadow-md md:w-1/3">
                    {errors.map((error) => (
                      <div key={error.message}>{error.message}</div>
                    ))}
                  </div>
                )}
                <Form method="post">
                  <div className="mt-5 flex w-full justify-center space-x-6">
                    <div className="w-full md:w-1/3">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="you@example.com"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-orange-600 sm:text-sm sm:leading-6"
                        required
                      />
                      <p
                        id="email-description"
                        className="mt-2 text-left text-sm text-white"
                        data-tina-field={tinaField(wait, "help_text")}
                      >
                        {helperText}
                      </p>
                    </div>
                    <input type="hidden" name="language" value={language} />
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="mt-0 h-10 w-auto items-center justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                      data-tina-field={tinaField(wait, "cta_label")}
                    >
                      {isLoading ? buttonWait : buttonLabel}
                    </button>
                  </div>
                </Form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
