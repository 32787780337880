import { Link } from "@remix-run/react";
import { ArrowRight } from "lucide-react";
import { PageBlocksBottom_Cta } from "tina/__generated__/types";
import { tinaField } from "tinacms/dist/react";

export default function BottomCTA(props: PageBlocksBottom_Cta) {
  return (
    <div className="relative -z-10 mt-32 px-6 lg:px-8">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
      >
        <div
          style={{
            clipPath:
              "polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)",
          }}
          className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#fde179] to-[#73cfa8] opacity-25"
        />
      </div>
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-3xl tracking-tight text-gray-900 sm:text-4xl">
          {props.message}
        </h2>
        {props.sub_message && (
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            {props.sub_message}
          </p>
        )}
        <div className="mt-10 flex items-center justify-center gap-x-8">
          {props.cta?.map((cta) => {
            if (!cta) return null;
            if (cta.primary) {
              return (
                <Link
                  data-tina-field={tinaField(cta, "label")}
                  to={cta.link}
                  key={cta.label}
                  className="group flex items-center justify-center space-x-6 rounded-full bg-zinc-800 py-1.5 pl-4 pr-2 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                  {cta.label}
                  <div className="ml-2 flex h-10 w-10 items-center justify-center rounded-full bg-blue-400 group-hover:bg-zinc-800">
                    <ArrowRight className="h-7 w-7 group-hover:translate-x-1 group-hover:scale-110 group-hover:transition-all" />
                  </div>
                </Link>
              );
            } else {
              return (
                <Link
                  data-tina-field={tinaField(cta, "label")}
                  to={cta.link}
                  key={cta.label}
                  className="group text-sm font-semibold leading-6 text-gray-900 transition delay-75 ease-in-out hover:scale-125 hover:text-blue-400"
                >
                  {cta.label} <span aria-hidden="true">→</span>
                </Link>
              );
            }
          })}
        </div>
      </div>
      <div
        aria-hidden="true"
        className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
      >
        <div
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        />
      </div>
    </div>
  );
}
